.typein {
  width: 25%;
  padding: 12px 20px;
}

.filterlist img { 
  width: 50px;
  border-radius: 25px;
}

.filterlist {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  justify-content: center;
  align-items: center;
}

.filterlist li {
  flex: 1;
  display: flex;
  justify-content: center;
}

.champion-container {
  display: flex;
  flex-direction: column;
  padding: 5px;
  max-width: 55px;

  justify-content: center;
  align-items: center;
}

.champion-name {
    font-family: Arial, sans-serif;
    font-size: 75%;
    font-weight: 600;
}

body {
  width: 98%;
  margin-left: 1%;
  margin-right: 1%;
}

.color-red {
    background-color: #FFC9BE;
}

.color-blue {
    background-color: #B5E9FF;
}

.color-green {
    background-color: #CFFFE0;
}


.container {
  display: flex;
  flex-direction: row;
  width: 99%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  flex-wrap: wrap;

  padding-top: 10px;
}

.column {
  width: 33%;
}








.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
